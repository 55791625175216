import _merge from 'lodash/merge';
import { addSingleObjectToState } from 'lib/reducerHelpers';
import { CHANGE_CURRENT_FACILITATOR,
  GET_DASHBOARD_FOR_FACILITATOR,
  GET_SECONDARY_DASHBOARD_DATA,
  LOAD_INITIAL_STATE,
  CHANGE_DASHBOARD_SORT_ORDER,
  LOAD_FACILITATOR_DISCUSSION_BOARDS,
  LOAD_COMMENTS,
  CREATE_REPLY_COMMENT,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
  DELETE_COMMENT,
} from 'store/actions';
import { LOAD_PARTICIPANT, LOAD_PARTICIPANT_IWL_STATUS } from './actions';

export const SORT_ORDER_TYPES = {
  START_DATE_NEWEST_FIRST: 'start_date_newest_first',
  START_DATE_OLDEST_FIRST: 'start_date_oldest_first',
  CLOSING_DATE_NEWEST_FIRST: 'closing_date_newest_first',
  CLOSING_DATE_OLDEST_FIRST: 'closing_date_oldest_first',
};

const initialState = {
  facilitators: {
    byId: {},
    allIds: [],
  },
  currentFacilitator: undefined,
  loadingFacilitatorData: true,
  subGroups: undefined,
  loadingSubGroups: false,
  loadingComments: false,
  sortOrder: SORT_ORDER_TYPES.START_DATE_OLDEST_FIRST,
  participants: {
    byId: {},
    allIds: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_STATE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_DASHBOARD_FOR_FACILITATOR.SUCCESS:
      return {
        ...state,
        subGroups: action.payload,
        loadingFacilitatorData: false,
      };
    case LOAD_FACILITATOR_DISCUSSION_BOARDS.REQUEST:
      return {
        ...state,
        loadingSubGroups: true,
      };
    case LOAD_FACILITATOR_DISCUSSION_BOARDS.SUCCESS: {
      const facilitator = state.facilitators.byId[action.payload.userId];
      return {
        ...state,
        currentFacilitator: { id: facilitator?.id, firstName: facilitator?.firstName, lastName: facilitator?.lastName },
        subGroups: action.payload.subGroups,
        loadingSubGroups: false,
      };
    }

    case GET_SECONDARY_DASHBOARD_DATA.SUCCESS:
      return {
        ...state,
        subGroups: _merge(
          {},
          state.subGroups,
          action.payload.insufficientParticipations,
          action.payload.extendedParticipants,
          action.payload.flaggedJournals,
        ),
      };
    case CHANGE_CURRENT_FACILITATOR.SYNC: {
      const facilitator = state.facilitators.byId[action.payload.id];

      return {
        ...state,
        currentFacilitator: { id: facilitator.id, firstName: facilitator.firstName, lastName: facilitator.lastName },
        loadingFacilitatorData: true,
      };
    }
    case CHANGE_DASHBOARD_SORT_ORDER.SYNC:
      return {
        ...state,
        sortOrder: action.payload.sortLabel,
      };

    case LOAD_COMMENTS.REQUEST:
      return {
        ...state,
        loadingComments: true,
      };

    case LOAD_COMMENTS.SUCCESS:
      return {
        ...state,
        loadingComments: false,
        commentIds: action.payload.commentIds,
        commentsAndRepliesById: action.payload.commentsById,
        unclearedNotifications: action.payload.unclearedNotifications,
        assignedFacilitatorId: action.payload.assignedFacilitatorId,
        subGroupFacilitatorIds: action.payload.subGroupFacilitatorIds,
      };

    case CREATE_REPLY_COMMENT.SUCCESS:
      return {
        ...state,
        commentsAndRepliesById: {
          ...state.commentsAndRepliesById,
          [action.payload.comment.id]: action.payload.comment,
          [action.payload.comment.commentableId]: {
            ...state.commentsAndRepliesById[action.payload.comment.commentableId],
            replyIds: [...state.commentsAndRepliesById[action.payload.comment.commentableId].replyIds, action.payload.comment.id],
          },
        },
      };

    case UPDATE_COMMENT.SUCCESS:
      return {
        ...state,
        commentsAndRepliesById: {
          ...state.commentsAndRepliesById,
          [action.payload.comment.id]: {
            ...state.commentsAndRepliesById[action.payload.comment.id],
            ...action.payload.comment,
          },
        },
      };

    case CREATE_COMMENT.SUCCESS:
      return {
        ...state,
        commentsAndRepliesById: {
          ...state.commentsAndRepliesById,
          [action.payload.comment.id]: action.payload.comment,
        },
        commentIds: [
          ...state.commentIds, action.payload.comment.id,
        ],
      };

    case MARK_NOTIFICATION_AS_READ.SUCCESS: {
      const { [action.payload.id]: removed, ...remainingNotifications } = state.unclearedNotifications;
      return {
        ...state,
        unclearedNotifications: remainingNotifications,
      };
    }

    case MARK_NOTIFICATION_AS_UNREAD.SUCCESS:
      return {
        ...state,
        unclearedNotifications: {
          ...state.unclearedNotifications,
          ...action.payload.data,
        },
      };

    case LOAD_PARTICIPANT.SUCCESS: {
      if (action.meta.cached) return state;

      return {
        ...state,
        participants: addSingleObjectToState(state.participants, action.payload),
      };
    }

    case LOAD_PARTICIPANT_IWL_STATUS.SUCCESS: {
      return {
        ...state,
        participants: {
          ...state.participants,
          byId: {
            ...state.participants.byId,
            [action.payload.groupMembershipId]: {
              ...state.participants.byId[action.payload.groupMembershipId],
              iwlStatus: action.payload,
            },
          },
        },
      };
    }

    case DELETE_COMMENT.SUCCESS: {
      const { [action.payload]: removed, ...remainingCommentsAndRepliesById } = state.commentsAndRepliesById;
      const index = state.commentIds.indexOf(action.payload);
      const updatedCommentIds = [...state.commentIds];
      updatedCommentIds.splice(index, 1);
      return {
        ...state,
        commentsAndRepliesById: remainingCommentsAndRepliesById,
        commentIds: updatedCommentIds,
      };
    }

    default:
      return state;
  }
};
