import Audio from './audio';
import Book from './book';
import BookmarkCheck from './bookmarkCheck';
import Bookmarks from './bookmarks';
import BoxArrowLeft from './boxArrowLeft';
import BoxArrowUpRight from './boxArrowUpRight';
import CameraVideo from './cameraVideo';
import CaretDownFill from './caretDownFill';
import Chat from './chat';
import ChatQuote from './chatQuote';
import ChatSquareQuote from './chatSquareQuote';
import CheckBox from './checkBox';
import CheckCircle from './checkCircle';
import Checkmark from './checkmark';
import ChevronDown from './chevronDown';
import Clock from './clock';
import CodeSlash from './codeSlash';
import Copy from './copy';
import DashCircle from './dashCircle';
import EggFried from './eggFried';
import Envelope from './envelope';
import ExclamationTriangle from './exclamationTriangle';
import Eye from './eye';
import File from './file';
import FileText from './fileText';
import Flower from './flower';
import FlowerSolid from './flowerSolid';
import GripHorizontal from './gripHorizontal';
import GripVertical from './gripVertical';
import Hummingbird from './hummingbird';
import Image from './image';
import InfoCircle from './infoCircle';
import InfoCircleFill from './infoCircleFill';
import KebabVertical from './kebabVertical';
import KebabHorizontal from './kebabHorizontal';
import Link from './link';
import Link45 from './link45';
import List from './list';
import Lock from './lock';
import Pencil from './pencil';
import People from './people';
import Person from './person';
import PersonCheck from './personCheck';
import PlusCircle from './plusCircle';
import ProfileInfo from './profileInfo';
import ShieldLock from './shieldLock';
import Square from './square';
import Textarea from './textarea';
import ToastCheck from './toastCheck';
import ToastError from './toastError';
import Toggles from './toggles';
import TrashCan from './trashCan';
import Unlock from './unlock';
import Upload from './upload';
import Video from './video';
import Discussion from './discussion';
import Xmark from './xmark';

const icons = {
  audio: Audio,
  book: Book,
  bookmarkCheck: BookmarkCheck,
  bookmarks: Bookmarks,
  boxArrowLeft: BoxArrowLeft,
  boxArrowUpRight: BoxArrowUpRight,
  cameraVideo: CameraVideo,
  caretDownFill: CaretDownFill,
  chat: Chat,
  chatQuote: ChatQuote,
  chatSquareQuote: ChatSquareQuote,
  checkBox: CheckBox,
  checkCircle: CheckCircle,
  checkmark: Checkmark,
  chevronDown: ChevronDown,
  clock: Clock,
  codeSlash: CodeSlash,
  copy: Copy,
  dashCircle: DashCircle,
  discussion: Discussion,
  eggFried: EggFried,
  envelope: Envelope,
  exclamationTriangle: ExclamationTriangle,
  eye: Eye,
  file: File,
  fileText: FileText,
  flower: Flower,
  flowerSolid: FlowerSolid,
  gripHorizontal: GripHorizontal,
  gripVertical: GripVertical,
  hummingbird: Hummingbird,
  image: Image,
  infoCircle: InfoCircle,
  infoCircleFill: InfoCircleFill,
  kebabHorizontal: KebabHorizontal,
  kebabVertical: KebabVertical,
  link: Link,
  link45: Link45,
  list: List,
  lock: Lock,
  pencil: Pencil,
  people: People,
  person: Person,
  personCheck: PersonCheck,
  plusCircle: PlusCircle,
  profileInfo: ProfileInfo,
  shieldLock: ShieldLock,
  square: Square,
  textarea: Textarea,
  toastCheck: ToastCheck,
  toastError: ToastError,
  toggles: Toggles,
  trashCan: TrashCan,
  unlock: Unlock,
  upload: Upload,
  video: Video,
  xmark: Xmark,
};

export const iconNames = Object.keys(icons);
export default icons;
