import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { SHORT_DATE } from 'lib/dateFormats';
import { DiscussionBoardNotificationPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COMMENTS_PATH } from 'lib/routerPaths';
import Badge from 'components/shared/Badge/Badge';

function DiscussionBoardRow({ discussionBoard }) {
  const {
    internalName,
    groupName,
    subGroupName,
    groupBeginSessionAt,
    groupCloseSessionAt,
    highPriorityTotal,
    mediumPriorityTotal,
    lowPriorityTotal,
    discussionBoardId: discussionTaskId,
    groupId: id,
    subGroupId,
  } = discussionBoard;

  return (
    <tr>
      <td className="text-start">
        <Badge
          variant="blue"
          title={internalName}
        />
      </td>
      <td className="fw-semibold text-start">{groupName}</td>
      <td className="text-start">{subGroupName}</td>
      <td className="text-nowrap text-start">
        {format(parseISO(groupBeginSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap text-start">
        {format(parseISO(groupCloseSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap text-start">
        <Badge
          variant="red"
          title={highPriorityTotal}
          className="me-1"
        />
        <Badge
          variant="orange"
          title={mediumPriorityTotal}
          className="me-1"
        />
        <Badge
          variant="green"
          title={lowPriorityTotal}
          className="me-1"
        />
      </td>
      <td>
        <Link to={buildRoutePath(FACILITATOR_COMMENTS_PATH, { id, subGroupId, discussionTaskId })}>
          View
        </Link>
      </td>
    </tr>
  );
}

DiscussionBoardRow.propTypes = {
  discussionBoard: DiscussionBoardNotificationPropType.isRequired,
};

export default DiscussionBoardRow;
