import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DELETE_COMMENT } from 'store/facilitatorDashboard/actions';
import Confirmation from 'components/shared/Modal/Confirmation';
import SanyasIcon from '../SanyasIcon';
import './styles.scss';

function CommentCardDropdown({ setIsEditable, commentId }) {
  const dispatch = useDispatch();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleConfirmDeleteClose = useCallback(() => {
    setConfirmDeleteOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(DELETE_COMMENT.request({ id: commentId }));
  }, [commentId, dispatch]);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="alt-secondary" size="sm" bsPrefix="no-arrow" className="mt-1">
          <SanyasIcon name="kebabHorizontal" size="xl" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={() => ''}>
            Flag
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setIsEditable(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setConfirmDeleteOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Confirmation
        title="Delete Post?"
        open={confirmDeleteOpen}
        close={handleConfirmDeleteClose}
        text="Are you sure you want to delete this post? This action cannot be undone."
        confirmText="Delete"
        confirmAction={handleDelete}
        cancelText={null}
      />
    </>
  );
}

CommentCardDropdown.propTypes = {
  setIsEditable: PropTypes.func.isRequired,
  commentId: PropTypes.number.isRequired,
};

export default CommentCardDropdown;
