import { takeLatest, put, select, call, delay } from 'redux-saga/effects';
import { fetchGet, fetchPost, fetchPut, fetchPatch, handleSimpleFetchError } from 'lib/apiHelpers';
import { GET_GROUP, ASSIGN_COURSE, EXTEND_GROUP, SHOW_SOMETHING_WENT_WRONG, UPDATE_GROUP_SETTINGS, GET_LIVE_FEED } from 'store/actions';
import { GET_GROUP_PARTICIPATIONS, LOAD_RESERVATIONS, UPDATE_RESERVATIONS, CREATE_PARTICIPANT, RESET_GROUP_STATUS, UPDATE_REVIEW } from 'store/groupShow/actions';
import { RESET_EMAIL_TEMPLATES } from 'store/emailTemplates/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';

export default function* sagas() {
  yield takeLatest(GET_GROUP.REQUEST, function* getGroup({ payload }) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace ?? 'registrar'}/groups/${payload.id || payload}`);
      yield put(GET_GROUP.success(data));
      yield put(RESET_EMAIL_TEMPLATES.action());
    } catch (err) {
      yield handleSimpleFetchError(err, GET_GROUP);
    }
  });

  yield takeLatest(GET_GROUP_PARTICIPATIONS.REQUEST, function* getGroup({ payload }) {
    try {
      const { data } = yield fetchGet(`/api/registrar/groups/${payload}/participations`);
      yield put(GET_GROUP_PARTICIPATIONS.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_GROUP_PARTICIPATIONS);
    }
  });

  yield takeLatest(CREATE_PARTICIPANT.REQUEST, function* createParticipant({ payload, meta }) {
    try {
      const data = yield fetchPost(`/api/registrar/groups/${payload.groupId}/create_participant`, payload);
      yield put(CREATE_PARTICIPANT.success(data));
      yield put(RESET_GROUP_STATUS.action());
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_PARTICIPANT);
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });

  yield takeLatest(GET_LIVE_FEED.REQUEST, function* getLiveFeed({ payload }) {
    try {
      const data = yield fetchGet(`/api/${payload.workspace}/groups/${payload.id}/live_feed.json`, { feed: payload.feed });
      yield put(GET_LIVE_FEED.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_LIVE_FEED);
    }
  });

  yield takeLatest(UPDATE_GROUP_SETTINGS.REQUEST, function* updateGroupSettings({ payload }) {
    try {
      const { data } = yield fetchPut(`/api/registrar/groups/${payload.id}`, payload);
      yield delay(500);
      yield put(UPDATE_GROUP_SETTINGS.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_GROUP_SETTINGS);
    }
  });

  yield takeLatest(ASSIGN_COURSE.REQUEST, function* assignCourse({ payload: groupId }) {
    try {
      const { data } = yield fetchPut(`/api/registrar/groups/${groupId}/initialize_course`);
      yield put(ASSIGN_COURSE.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, ASSIGN_COURSE);
    }
  });

  yield takeLatest(EXTEND_GROUP.REQUEST, function* extendGroup({ payload }) {
    const { groupShow: { group } } = yield select();
    try {
      const response = yield fetchPost(
        `/api/registrar/groups/${group.id}/update_extension`,
        {
          groupExtension: {
            groupId: group.id,
            groupMembershipIds: payload.groupMembershipIds,
            extendedUntil: (payload.extendedUntil || group.extendedUntil),
          },
        },
      );
      yield put(EXTEND_GROUP.success(response.data));
      yield put(SET_NOTIFICATION.action({
        message: `Extension ${group.extendedUntil ? 'updated' : 'created'} successfully.`,
        type: 'success',
      }));
    } catch (err) {
      if (err && (err.details)) {
        yield put(EXTEND_GROUP.error({ message: err.details.message }));
      } else {
        // this is most likely a 500 error
        yield put(SHOW_SOMETHING_WENT_WRONG.action());
      }
      yield put(SET_NOTIFICATION.action({
        message: 'There was a problem saving the extension.',
        type: 'error',
        timeout: 0,
      }));
    }
  });

  yield takeLatest(LOAD_RESERVATIONS.REQUEST, function* loadReservations({ payload }) {
    try {
      const { data } = yield fetchGet(`/api/registrar/groups/${payload.groupId}/reservations`);
      yield put(LOAD_RESERVATIONS.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_RESERVATIONS);
    }
  });

  yield takeLatest(UPDATE_RESERVATIONS.REQUEST, function* updateReservations({ payload, meta }) {
    try {
      const { data } = yield fetchPut(`/api/registrar/groups/${payload.groupId}/reservations`, payload);
      yield delay(400); // To give the user a chance to see the spinner
      yield put(UPDATE_RESERVATIONS.success(data));
      yield put(SET_NOTIFICATION.action({
        message: 'Reservations updated successfully.',
        type: 'success',
      }));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_RESERVATIONS);
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });

  yield takeLatest(UPDATE_REVIEW.REQUEST, function* updateReview({ payload }) {
    let notificationMessage;
    if (payload.review) {
      notificationMessage = 'Group has been marked as a review group successfully.';
    } else {
      notificationMessage = 'Group is no longer marked as a review group.';
    }
    try {
      const { data } = yield fetchPatch(`/api/registrar/groups/${payload.groupId}/update_review_flag`, payload);
      yield put(UPDATE_REVIEW.success(data));
      yield put(SET_NOTIFICATION.action({
        message: notificationMessage,
        type: 'success',
      }));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_REVIEW);
    }
  });
}
