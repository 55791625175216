import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { simpleFormat } from 'lib/utils';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import ParticipantInfoPopover from 'components/group_memberships/ParticipantInfoPopover';
import SanyasIcon from 'components/shared/SanyasIcon';
import CommentCardDropdown from './CommentCardDropdown';
import EditCommentCard from './EditCommentCard';
import Reply from './Reply';
import './styles.scss';

function CommentCard({ commentId, isReply }) {
  const dispatch = useDispatch();
  const params = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showRead, setShowRead] = useState(true);
  const [editable, setIsEditable] = useState(false);
  const [notificationId, setNotificationId] = useState();
  const { assignedFacilitatorId, unclearedNotifications } = useSelector((state) => state.facilitatorDashboard);
  const comment = useSelector((state) => state.facilitatorDashboard.commentsAndRepliesById[commentId]);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  useEffect(() => {
    if (unclearedNotifications) {
      Object.keys(unclearedNotifications).forEach((key) => {
        if (unclearedNotifications[key].triggerId === comment.id) {
          setShowRead(false);
          setNotificationId(unclearedNotifications[key].id);
        }
      });
    }
  }, [comment?.id, dispatch, unclearedNotifications]);

  const markComment = useCallback((isChecked) => {
    setShowRead(isChecked);
    if (isChecked) {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: notificationId, subGroupId: comment.subGroupId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ commentId: comment.id, subGroupId: comment.subGroupId }));
    }
    document.activeElement.blur();
  }, [comment.id, comment.subGroupId, dispatch, notificationId]);

  if (!comment) return null;

  return (
    <div className={`mb-2  ${isReply ? 'col-md-9 justify-content-end' : 'col-md-11'}`}>
      {editable
        ? (
          <div className="col-md-12 mx-auto d-flex flex-column align-items-end">
            <EditCommentCard commentId={comment.id} isReply setIsEditable={setIsEditable} comment={comment} discussionTaskId={params.discussionTaskId} subGroupId={params.subGroupId} />
          </div>
        ) : (
          <>
            <div className="card bg-white shadow-sm rounded mb-3 pt-3 px-4">
              <div className="d-flex justify-content-between position-relative">
                {!showRead && (
                  <div className="position-absolute top-50 start-0 translate-middle" style={{ paddingRight: '1.375rem' }}>
                    <span className="d-block" style={{ backgroundColor: 'var(--bs-sn-clr-land)', borderRadius: 7, width: 7, height: 7 }} />
                  </div>
                )}

                <div className="d-flex align-items-baseline">
                  {comment.isFacilitatorComment && (
                    <span className="text-accent-purple">
                      <SanyasIcon name="hummingbird" className="me-1" />
                      {comment.author}
                    </span>
                  )}
                  {!comment.isFacilitatorComment && (
                    <>
                      <ParticipantInfoPopover participantId={comment.groupMembershipId} />
                      <a
                        href={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: comment.groupMembershipId })}
                        className="btn-plain"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {comment.author}
                      </a>
                    </>
                  )}
                </div>

                {!comment.isFacilitatorComment && isAssignedFacilitationPoint && (
                  <label className="btn-plain align-self-center read-label btn-primary" htmlFor={comment?.id}>
                    <input
                      onChange={() => markComment(!showRead)}
                      checked={showRead}
                      id={comment?.id}
                      name={comment?.author}
                      type="checkbox"
                      className="form-check-input me-2"
                    />
                    {showRead ? 'Read' : 'Unread'}
                  </label>
                )}
              </div>

              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: simpleFormat(comment.comment) }} />

              <div className="d-flex justify-content-end mb-1">
                {!isReply && (
                  <Button onClick={() => setShowReplyBox(!showReplyBox)} variant="plain" className="me-1 px-1">
                    <SanyasIcon name="chat" className="me-1" />
                    Reply
                  </Button>
                )}
                <CommentCardDropdown commentId={comment.id} setIsEditable={setIsEditable} />
              </div>
            </div>
            <div className="d-flex justify-content-end col-md-8 w-100">
              {showReplyBox ? <div className="col-md-10"><Reply setShowReplyBox={setShowReplyBox} comment={comment} /></div> : null}
            </div>
          </>
        )}
    </div>
  );
}

CommentCard.defaultProps = {
  isReply: undefined,
};

CommentCard.propTypes = {
  commentId: PropTypes.number.isRequired,
  isReply: PropTypes.bool,
};

export default CommentCard;
