import _flatten from 'lodash/flatten';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import { add, getHours, getMinutes, parse, parseISO } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { COMPACT_DATE } from 'lib/dateFormats';
import { SORT_ORDER_TYPES } from './reducer';

export const fetchDashboard = (state) => state.facilitatorDashboard?.subGroups;
export const dashboardSortOrder = (state) => state.facilitatorDashboard?.sortOrder;

export const facilitatorIds = (state) => state.facilitatorDashboard?.facilitators.allIds;
export const facilitatorById = (state) => state.facilitatorDashboard?.facilitators.byId;

export const fetchSubGroupData = (state, id) => state.facilitatorDashboard?.subGroups?.[id];

function calculateTotalsForSubGroup(subGroupData) {
  function addHighMidLow(prevTotal, obj) {
    return prevTotal + obj.highPriorityTotal + obj.mediumPriorityTotal + obj.lowPriorityTotal;
  }
  // Some groups do not have discussion boards
  const dbNotifications = subGroupData.discussionBoardNotifications ? Object.values(subGroupData.discussionBoardNotifications) : [];
  const discussionBoardTotals = dbNotifications.reduce(addHighMidLow, 0);
  // Some groups do not have journals
  const journalNotifications = subGroupData.journalNotifications ? Object.values(subGroupData.journalNotifications) : [];
  const journalTotals = _flatten(journalNotifications.map((ja) => [ja.primary, ja.replies])).reduce(addHighMidLow, 0);

  return discussionBoardTotals + journalTotals;
}

export const selectFacilitators = createSelector(
  facilitatorIds,
  facilitatorById,
  (ids, byId) => ids.map((id) => byId[id]),
);

export const activatedFacilitators = createSelector(
  selectFacilitators,
  (facilitators) => facilitators.filter((v) => v.activation === 'activated'),
);

export const notificationTotalsForSubGroup = createSelector(
  fetchSubGroupData,
  calculateTotalsForSubGroup,
);

export const notificationTotals = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return null;
    return _reduce(dashboardData, (result, value) => result + calculateTotalsForSubGroup(value), 0);
  },
);

/**
 * Different courses have different numbers of journals.
 * This selector returns the maximum number of journals for a given collection
 * of pods.
 */
export const selectMaxJournals = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return 0;
    const numJournalsPerPod = Object.values(dashboardData)
      .map((pod) => {
        if (!pod.journalNotifications) return 0;
        return Object.keys(pod.journalNotifications).length;
      });
    if (numJournalsPerPod.length === 0) return 0;
    return Math.max(...numJournalsPerPod);
  },
);

/**
 * Similar to maxJournals.
 */
export const selectMaxDBs = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return 0;
    const numDBsPerPod = Object.values(dashboardData)
      .map((pod) => {
        if (!pod.discussionBoardNotifications) return 0;
        return Object.keys(pod.discussionBoardNotifications).length;
      });
    if (numDBsPerPod.length === 0) return 0;
    return Math.max(...numDBsPerPod);
  },
);

export const selectOpsSubGroups = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return null;
    return Object.values(dashboardData).filter((sg) => sg.isOps);
  },
);

export const sortedSubGroups = createSelector(
  fetchDashboard,
  dashboardSortOrder,
  (dashboardData, sortOrder) => {
    if (!dashboardData) return null;

    function sortByBeginSessionAt(a, b) {
      // requires that the beginSessionAt timestamps are ISO8601 which is sortable
      if (a.groupBeginSessionAt < b.groupBeginSessionAt) return -1;
      if (a.groupBeginSessionAt > b.groupBeginSessionAt) return 1;
      return 0; // default return value (no sorting)
    }

    function sortByCloseSessionAt(a, b) {
      const aCloseSessionAt = calculateCloseSessionAt(a);
      const bCloseSessionAt = calculateCloseSessionAt(b);
      // requires that the closeSessionAt timestamps are ISO8601 which is sortable
      if (aCloseSessionAt < bCloseSessionAt) return -1;
      if (aCloseSessionAt > bCloseSessionAt) return 1;
      return 0; // default return value (no sorting)
    }

    const dashboardDataArray = Object.values(dashboardData);

    switch (sortOrder) {
      case SORT_ORDER_TYPES.START_DATE_NEWEST_FIRST:
        return dashboardDataArray.sort((a, b) => sortByBeginSessionAt(b, a));
      case SORT_ORDER_TYPES.CLOSING_DATE_OLDEST_FIRST:
        return dashboardDataArray.sort((a, b) => sortByCloseSessionAt(a, b));
      case SORT_ORDER_TYPES.CLOSING_DATE_NEWEST_FIRST:
        return dashboardDataArray.sort((a, b) => sortByCloseSessionAt(b, a));
      default:
        return dashboardDataArray.sort((a, b) => sortByBeginSessionAt(a, b));
    }
  },
);

function calculateCloseSessionAt({ groupCloseSessionAt, groupExtendedUntil, extendedParticipants }) {
  if (groupExtendedUntil && extendedParticipants?.length) {
    const close = parseISO(groupCloseSessionAt);
    const extended = add(
      parse(groupExtendedUntil, COMPACT_DATE, new Date()),
      { hours: getHours(close), minutes: getMinutes(close) },
    );
    // add character (e.g., 0) to ensure extended is sorted after close
    return formatInTimeZone(extended, 'UTC', "y-MM-dd'T'HH:mm:ss.SSSXX0");
  }

  return groupCloseSessionAt;
}

export const sortedDBNotifications = createSelector(
  sortedSubGroups,
  (subGroups) => {
    if (!subGroups) return null;

    return _flatten(subGroups.map(
      (sg) => {
        const sortedDBs = _sortBy(Object.values(sg.discussionBoardNotifications), 'position');
        return sortedDBs.map((db) => ({
          ...db,
          key: `${sg.groupId}-${sg.subGroupId}-${db.discussionBoardId}`,
          subGroupName: sg.subGroupName,
          groupId: sg.groupId,
          groupName: sg.groupName,
          groupBeginSessionAt: sg.groupBeginSessionAt,
          groupCloseSessionAt: sg.groupCloseSessionAt,
        }));
      },
    ));
  },
);
